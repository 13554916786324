import styled, { css } from 'styled-components';

export const List = styled.ul`
    ${({ theme }) => css`
        list-style: none;
        display: grid;
        gap: 1rem;
        padding: 0;
        grid-template-columns: repeat(2, 1fr);

        @media ${theme.breakpoints.medium.media} {
            gap: 1.5rem;
            grid-template-columns: repeat(4, 1fr);
        }

        @media ${theme.breakpoints.xlarge.media} {
            grid-template-columns: repeat(5, 1fr);
        }

        @media ${theme.breakpoints.colossal.media} {
            grid-template-columns: repeat(6, 1fr);
        }
    `}
`;
