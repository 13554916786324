import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

import { heading3 } from 'Global/Typography';
import Link from 'Elements/Link';

const containerStyle = css`
    display: block;
    text-decoration: none;
    color: inherit;
    width: 100%;
`;

export const Container = styled.div`
    ${containerStyle}
`;

export const LinkContainer = styled(Link)`
    ${containerStyle}
`;

export const BrandCircle = styled.div<{ $borderColor?: string }>`
    ${({ theme, $borderColor }) => css`
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 100%;
        height: 0;
        padding-top: 100%;
        border-radius: 50%;
        background-color: ${$borderColor ? $borderColor : theme.colors.secondary.tints[0].hex};
    `}
`;

export const ImageContainer = styled(animated.div)`
    position: absolute;
    overflow: hidden;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    height: 95%;
`;

export const ImageWrapper = styled(animated.div)`
    position: absolute;
    top: -12.5%;
    height: 125%;
    width: 125%;
`;

export const Inner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const HeadingStyles = ({ theme }) => css`
    font-size: 1.125rem;
    text-align: center;
    margin: 0.5rem 0 0;

    @media ${theme.breakpoints.medium.media} {
        font-size: 1.25rem;
    }

    @media ${theme.breakpoints.large.media} {
        font-size: 1.5rem;
        margin-top: 0.5rem;
    }
`;

export const Heading = styled.h3`
    ${HeadingStyles}
    line-height: 1.4;
`;

export const Button = styled.button`
    ${heading3}
    ${HeadingStyles}
    border: none;
    background: transparent;

    &:hover {
        cursor: pointer;
    }
`;

export const FurtherDetails = styled.p`
    ${({ theme }) => css`
        font-size: 1rem;
        text-align: center;
        margin: 0;

        @media ${theme.breakpoints.medium.media} {
            font-size: 1.125rem;
        }
    `}
`;
