import React, { useState } from 'react';
import { useSpring } from '@react-spring/web';
import NextImage, { StaticImageData } from 'next/image';

import type { Props as LinkProps } from 'Elements/Link';

import {
    Container,
    LinkContainer,
    ImageContainer,
    BrandCircle,
    Heading,
    Button,
    FurtherDetails,
    Inner,
    ImageWrapper
} from './Mini.styled';

export interface Props {
    image: {
        src: StaticImageData | string;
        alt?: string;
    };
    link?: LinkProps;
    onClick?: {
        fnc: () => void;
        ariaLabel: string;
    };
    heading?: string | undefined;
    furtherDetails?: string | undefined;
    id: string;
    borderColor?: string;
}

const Mini: React.FC<Props> = props => {
    const { image, heading, furtherDetails, link, onClick, borderColor } = props;
    const [isHovered, setIsHovered] = useState(false);

    const imageSpring = useSpring({
        transform: isHovered ? 'scale(0.85) translateZ(0)' : 'scale(1.05) translateZ(0)',
        config: { mass: 1.5, tension: 300, friction: 40 }
    });
    const wrapperSpring = useSpring({
        transform: isHovered
            ? 'translate(-50%, -50%) scale(1.1)'
            : 'translate(-50%, -50%) scale(1)',
        config: { mass: 1.5, tension: 200, friction: 30 }
    });

    return (
        <Container
            {...link}
            label={undefined}
            as={link ? LinkContainer : undefined}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            data-hover={isHovered}
            onClick={onClick ? onClick.fnc : undefined}
        >
            <BrandCircle $borderColor={borderColor}>
                <ImageContainer style={wrapperSpring}>
                    <ImageWrapper style={imageSpring}>
                        <NextImage
                            src={image.src}
                            alt={image.alt || ''}
                            fill
                            style={{ objectFit: 'cover' }}
                        />
                    </ImageWrapper>
                </ImageContainer>
            </BrandCircle>
            <Inner>
                {heading &&
                    (onClick ? (
                        <Heading>
                            <Button onClick={onClick.fnc} aria-label={onClick.ariaLabel}>
                                {heading}
                            </Button>
                        </Heading>
                    ) : (
                        <Heading>{heading}</Heading>
                    ))}
                {furtherDetails && <FurtherDetails>{furtherDetails}</FurtherDetails>}
            </Inner>
        </Container>
    );
};

export default Mini;
