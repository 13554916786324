import styled, { css } from 'styled-components';
import Link from 'Elements/Link';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        margin-top: 0;

        @media ${theme.breakpoints.large.media} {
            margin-top: 2.5rem;
        }
    `}
`;

export const Container = styled.nav`
    ${({ theme }) => css`
        padding-bottom: 0.25rem;
        position: relative;
        background-color: rgb(${theme.colors.tertiary.rgb}, 0.8);
        border-radius: 0.5rem;
        transform: translateZ(0);
        margin-top: 0.5rem;
        padding: 0.75rem 0;

        @media ${theme.breakpoints.large.media} {
            padding-top: 2.5rem;
            margin-top: 0;
            padding: 1.5rem 0;

            & :first-child {
                margin-top: 0;
            }
        }
    `}
`;

export const List = styled.ul`
    ${({ theme }) => css`
        padding-left: 0;
        list-style: none;
        text-align: center;
        margin: 0;

        @media ${theme.breakpoints.large.media} {
            text-align: left;
        }

        :before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 1.5rem;
            width: 100%;
            opacity: 0.8;
            margin-top: -2.5rem;
            border-radius: 1rem;
            transform: translateZ(0);
            background-color: ${theme.colors.tertiary.hex};
            display: none;

            @media ${theme.breakpoints.large.media} {
                display: block;
            }
        }
    `}
`;

export const ListItem = styled.li`
    position: relative;
    margin-bottom: 1rem;
    padding: 0.5rem 1.5rem;
    transition: all 0.25s ease;
    font-size: 1.25rem;

    :last-of-type {
        margin-bottom: 0;
    }

    :hover {
        background-color: rgb(11, 16, 40, 0.9);
        padding-left: 1.75rem;
    }
`;

export const NavLink = styled(Link)`
    ${({ theme }) => css`
        color: ${theme.colors.primaryContrast.hex};
        text-decoration: none;

        :after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    `}
`;

export const Heading = styled.h3`
    ${({ theme }) => css`
        color: ${theme.colors.primaryContrast.hex};
        padding: 0 1.5rem;
        margin: 0.5rem 0 0.5rem;
        display: none;

        @media ${theme.breakpoints.large.media} {
            display: block;
        }
    `}
`;

export const NavigationWrapper = styled.div`
    ${({ theme }) => css`
        display: none;

        @media ${theme.breakpoints.large.media} {
            display: block;
        }
    `}
`;
