import React, { useState, useRef } from 'react';
import useViewport from 'Common/hooks/useViewport';
import useDidUpdateEffect from '@use-effect/did-update';
import { useSpring } from '@react-spring/web';

import { StaticImageData } from 'next/image';
import type { AccordionVariants } from 'Theme/types/components.type';

import { Plus, Minus } from '@solent-university/solent-icons';

import {
    Container,
    Icon,
    StyledIcon,
    Expand,
    ExpandText,
    ExpandIcon,
    Content,
    Heading,
    Button
} from './Accordion.styled';

export interface Props {
    id: string;
    icon?: {
        src?: string | StaticImageData;
        as?: React.ElementType;
    };
    button: {
        ariaLabel: string;
    };
    heading: string | React.ReactNode;
    defaultOpen?: boolean;
    children: string | React.ReactNode;
    headingType?: 'h2' | 'h3';
    className?: string;
    onToggleOpen?: () => void;
    variant?: AccordionVariants;
    isBackgroundSameColour?: boolean;
}

const Accordion: React.FC<Props> = props => {
    const {
        id,
        className = '',
        icon,
        button,
        children,
        heading,
        headingType = 'h3',
        defaultOpen = false,
        onToggleOpen,
        variant = 'default',
        isBackgroundSameColour = false
    } = props;
    const [isOpen, setIsOpen] = useState(defaultOpen);
    const [contentHeight, setContentHeight] = useState(defaultOpen ? 'auto' : 0);
    const { width } = useViewport();
    const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    const contentSpring = useSpring({
        height: isOpen ? contentHeight : 0,
        opacity: isOpen ? 1 : 0,
        config: { mass: 1, tension: 180, friction: 30 }
    });

    useDidUpdateEffect(() => {
        // Only update content height if contentHeight isn't auto
        if (isOpen && contentHeight !== 'auto') {
            const { scrollHeight } = contentRef.current;

            if (scrollHeight === 0) {
                // If scrollHeight === 0, then accordion isn't visible and should close if not already
                setIsOpen(false);
            } else {
                // Update the content height
                setContentHeight(contentRef.current.scrollHeight);
            }
        }
    }, [width, isOpen]);

    useDidUpdateEffect(() => {
        if (defaultOpen !== isOpen) {
            setIsOpen(defaultOpen);
            defaultOpen && setContentHeight('auto');
        }
    }, [defaultOpen]);

    const accordionButton = (
        <Button
            onClick={() => {
                setIsOpen(!isOpen);
                onToggleOpen && onToggleOpen();
            }}
            aria-label={button.ariaLabel}
            aria-expanded={isOpen}
            aria-controls={`accordion_content_${id}`}
            $isOpen={isOpen}
            $variant={variant}
            id={`accordion_button_${id}`}
            className="c-accordion-button"
        >
            {icon && icon.src && <Icon alt="" src={icon.src} loading="lazy" />}
            {icon && icon.as && <StyledIcon as={icon.as} />}
            <ExpandText>{heading}</ExpandText>
            <Expand $variant={variant}>
                <ExpandIcon as={isOpen ? Minus : Plus} />
            </Expand>
        </Button>
    );

    return (
        <Container
            className={`c-accodion ${className}`.trim()}
            $isBackgroundSameColour={isBackgroundSameColour}
            $variant={variant}
        >
            <Heading as={headingType}>{accordionButton}</Heading>
            <Content
                id={`accordion_content_${id}`}
                ref={contentRef}
                style={contentSpring}
                role="region"
                aria-labelledby={`accordion_button_${id}`}
                aria-hidden={!isOpen}
                $isOpen={isOpen}
                $variant={variant}
            >
                {children}
            </Content>
        </Container>
    );
};

export default Accordion;
