import React from 'react';
import MiniCard, { MiniCardProps } from 'Components/Card/Mini';

import { List } from './Mini.styled';

export interface Props {
    items: MiniCardProps[];
}

const Mini: React.FC<Props> = props => {
    const { items } = props;

    return (
        <List>
            {items.map(card => (
                <li key={card.id}>
                    <MiniCard {...card} />
                </li>
            ))}
        </List>
    );
};

export default Mini;
