import styled, { css } from 'styled-components';

import Image from 'Elements/Image';

export const ListContainer = styled.ul`
    margin: 0;
    padding-left: 0;
    width: 100%;

    p + & {
        padding-top: 1rem;
    }
`;

export const ListItem = styled.li`
    ${({ theme }) => css`
        display: flex;
        list-style: none;
        padding-bottom: 0.84rem;
        padding-top: 0.6rem;
        font-size: 1.125rem;
        width: 100%;

        &:not(:last-of-type) {
            border-bottom: 0.06rem solid ${theme.colors.secondary.tints[0].hex};
        }

        &:first-of-type {
            padding-top: 0rem;
        }

        &:last-of-type {
            padding-bottom: 0rem;
        }

        @media ${theme.breakpoints.large.media} {
            font-size: 1.25rem;
            align-items: center;
        }
    `}
`;

export const ItemWrapper = styled.div`
    ${({ theme }) => css`
        padding: 0 0.75rem;

        @media ${theme.breakpoints.medium.media} {
            padding: 0;
        }
    `}
`;

export const Icon = styled.svg`
    ${({ theme }) => css`
        margin-top: 0.25rem;
        width: 2rem;
        min-width: 2rem;
        height: 2rem;
        min-height: 2rem;
        margin-right: 0.5rem;
        color: var(--linelist-icon-colour, ${theme.colors.primary.hex});

        @media ${theme.breakpoints.large.media} {
            width: 3rem;
            min-width: 3rem;
            height: 3rem;
            min-height: 3rem;
            margin-right: 1.25rem;
        }
    `}
`;

export const CustomImage = styled(Image)`
    margin-top: 0.25rem;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1.25rem;
`;
