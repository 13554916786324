import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

import type { AccordionVariants } from 'Theme/types/components.type';

import { Wrapper as StratNavWrapper } from 'Modules/SectionNavigation/Strategy/Strategy.styled';

import Image from 'Elements/Image';
import { Content as SupportBlockContent } from 'Components/SupportBlock/SupportBlock.styled';

export const Container = styled.div<{
    $isBackgroundSameColour: boolean;
    $variant: AccordionVariants;
}>`
    ${({ theme, $isBackgroundSameColour, $variant }) => css`
        overflow-x: hidden;
        background: ${theme.components.accordion[$variant].containerBackgroundColor};
        border-radius: 0.5rem;

        & + & {
            border-top: 0.0625rem solid ${theme.colors.secondary.tints[1].hex};
        }

        ${$isBackgroundSameColour
            ? css`
                  padding: 0.5rem 0;
              `
            : css`
                  margin: 1rem 0;
              `}

        ${StratNavWrapper} & {
            display: block;
            padding: 0;
            margin-bottom: 1rem;
            background: none;

            @media ${theme.breakpoints.large.media} {
                display: none;
            }
        }
    `}
`;

export const Button = styled.button<{ $isOpen: boolean; $variant: AccordionVariants }>`
    ${({ theme, $isOpen, $variant }) => css`
        width: 100%;
        display: grid;
        align-items: center;
        cursor: pointer;
        border: none;
        background: ${theme.components.accordion[$variant].buttonBackground};
        color: ${theme.components.accordion[$variant].headingColor};
        display: flex;
        position: relative;
        font-size: inherit;
        font-weight: inherit;
        line-height: 1.25;
        letter-spacing: inherit;
        text-transform: inherit;
        text-align: inherit;
        padding: 1rem 1.25rem;
        border-radius: 0.5rem;

        ${StratNavWrapper} & {
            justify-content: center;
            background-color: rgb(${theme.colors.tertiary.rgb}, 0.8);
            color: ${theme.colors.primaryContrast.hex};

            :focus {
                border: 0.225rem solid black;
            }
        }

        ${!$isOpen &&
        css`
            &:hover {
                background: ${theme.components.accordion[$variant].hoverColor};

                ${StratNavWrapper} & {
                    background: ${theme.colors.tertiary.hex};
                }
            }
        `}

        ${$isOpen &&
        css`
            background: ${theme.components.accordion[$variant].open.background};
            color: ${theme.components.accordion[$variant].open.color};

            ${Expand} {
                background: ${theme.components.accordion[$variant].open.iconBackground};
                color: ${theme.components.accordion[$variant].open.iconColor};
            }
        `}
    `}
`;

export const Icon = styled(Image)`
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    object-fit: contain;
    position: relative;
    margin-right: 0.75rem;

    @media ${({ theme }) => theme.breakpoints.medium.media} {
        margin-right: 1rem;
        width: 1.75rem;
        height: 1.75rem;
    }
`;

export const StyledIcon = styled.svg`
    ${({ theme }) => css`
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
        margin-right: 0.75rem;
        color: ${theme.colors.primary.hex};
        stroke: ${theme.colors.primary.hex};

        ${StratNavWrapper} & {
            color: white;
        }

        @media ${theme.breakpoints.medium.media} {
            margin-right: 1rem;
            width: 2.75rem;
            height: 2.75rem;
        }
    `}
`;

export const HeadingContainer = styled.div`
    & > *:first-child {
        margin: 0;
    }
`;

const HeadingCSS = css`
    margin: 0;
    text-align: left;
`;

export const Heading = styled.h2`
    && {
        ${HeadingCSS}
        line-height: 1.25;
    }

    ${SupportBlockContent} & {
        font-size: 1.125rem;
        line-height: 1.6;

        @media ${({ theme }) => theme.breakpoints.medium.media} {
            font-size: 1.25rem;
        }
    }
`;

export const Expand = styled.span<{ $variant: AccordionVariants }>`
    ${({ theme, $variant }) => css`
        margin-left: auto;
        background: ${theme.components.accordion[$variant].iconBackground};
        color: ${theme.components.accordion[$variant].iconColor};
        border-radius: 50%;
        height: 1.5rem;
        width: 1.5rem;
        min-width: 1.5rem;
        display: grid;
        place-items: center;

        ${StratNavWrapper} & {
            margin-left: unset;
            background: none;
        }

        @media ${theme.breakpoints.large.media} {
            height: 2rem;
            width: 2rem;
            min-width: 2rem;
        }
    `}
`;

export const ExpandText = styled.span`
    margin-right: 0.75rem;
`;

export const ExpandIcon = styled.svg`
    display: inline-block;
    height: 1.125rem;
    width: 1.125rem;
`;

export const Content = styled(animated.div)<{ $isOpen: boolean; $variant: AccordionVariants }>`
    margin-bottom: 0rem;
    overflow: hidden;
    padding: 0 0.25rem;

    ${StratNavWrapper} & {
        padding: 0;
    }

    ${({ theme, $isOpen, $variant }) => css`
        margin-bottom: 0rem;
        overflow: hidden;
        padding: ${theme.components.accordion[$variant].contentPadding};
        visibility: ${$isOpen ? 'visible' : 'hidden'};

        &:first-child {
            margin-top: 1rem;
        }
    `}
`;
